import React from "react";
import Page from "../components/Page";
import { Link } from "gatsby";
import QoutesPage from "../components/Citat/QoutesPage";


export default function Citat({ data }) {

  return (
    <>
    <Page
        title={"En samling med de bästa citaten"}
        description="En samling med de allra bästa citaten. Citat om livet, motivation, framgång, vänskap och mycket mer."
      >    



        <QoutesPage title="En samling med de bästa citaten">
        <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">En samling med de bästa citaten</h1>
          <p>
            Nedan har vi samlat olika citat inom olika kategorier. Vår avsikt är
            att det skall kunna ge dig motivation och inspiration i vardagen. Vi
            hoppas att du gillar det!
          </p>

          <h4>
            <Link to="/citat/livet/">De bästa citaten om livet</Link>
          </h4>
          <p>Vad är egentligen mening med livet? Få nya insikter med dessa inspirerande citaten.</p>
          <h4>
            <Link to="/citat/motivation/">De bästa citaten om motivation</Link>
          </h4>
          <p>Bli motiverad och inspirerad i vardagen, perfekta citat om du känner att du saknar motivation just nu. </p>
          <h4>
            <Link to="/citat/framgång/">De bästa citaten om framgång</Link>
          </h4>
          <p>Vill du uppnå framgång eller bli framgångsrik? Bli motiverad att arbeta mot dina mål med dessa grymma citaten om framgång.</p>
          <h4>
            <Link to="/citat/stoicismen/">De bästa citaten om stoicismen</Link>
          </h4>
          <p>Bli inspirerad med av bästa citaten från några av de största stoiska filosoferna, såsom Marcus Aurelius, Epictetus och Seneca. </p>
        </QoutesPage>
      </Page>
    </>
  );
}

